<template>
  <data-list
    ref="dataList"
    class="content md"
    url="/apply-temp"
    query="&sort=id&filter[is_oa]=6"
    entity-name="业务"
    add-btn="新增业务"
    :action="action"
    :columns="columns"
    :search-key-type="{ name: 1 }"
    add-route="VolunteerApplyAdd"
    @detail="handleDetail"
    @edit="handleEdit"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="">
        <a-input v-model="form.name" placeholder="输入业务名称查询" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:action="{ item }">
      <router-link
        :to="{ name: 'VolunteerProcess', params: { id: item.id } }"
        class="txt-btn"
        style="margin-left: 8px"
        @click="setProcess(item)"
      >
        流程设置
      </router-link>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "VolunteerProcess",
  data() {
    return {
      columns: [{ title: "业务名称", dataIndex: "name" }],
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  methods: {
    setProcess() {},
    handleDetail({ item }) {
      this.$router.push({
        name: "VolunteerApplyDetail",
        params: {
          id: item.id,
        },
      });
    },
    handleEdit(form) {
      this.$router.push({
        name: "VolunteerApplyDetail",
        params: {
          id: form.id,
        },
        query: {
          edit: 1,
        },
      });
    },
  },
};
</script>

<style scoped></style>
